import axios from "axios";
import config from "./config";

//add token in headers
const headersConfig = (token) => {
  const head = { headers: { Authorization: `Bearer ${token}` } };
  return head;
};

export default {
  settings: (token) => {
    return axios.get(`${config.URL_END_POINT}/settings`, headersConfig(token));
  },
  updateSettings: (id, data, token) => {
    return axios.put(
      `${config.URL_END_POINT}/settings/update/${id}`,
      data,
      headersConfig(token)
    );
  },
};
