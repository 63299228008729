import React, { Component } from "react";
import troubleshooting from "../../assets/images/under-construction.png";

class Maintenance extends Component {
  // componentDidMount() {
  //   localStorage.clear();
  // }

  render() {
    {
      /* page title in browser */
    }
    document.title = "LIVO | " + "Mode maintenance";
    {
      /* eof */
    }
    return (
      <>
        <div className="vh-100 st-bg-yellow d-flex flex-column justify-content-center align-items-center">
          <img src={troubleshooting} style={{ width: "15%" }} alt="" />
          <h1 className="text-white text-center mt-5">Site en construction</h1>
          <h4 className="mb-2 text-white">
            Votre site sera bientôt disponible.
          </h4>
        </div>
      </>
    );
  }
}

export default Maintenance;
