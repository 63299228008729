import axios from "axios";
import config from "./config";

const headersConfig = (token) => {
  const head = { headers: { Authorization: `Bearer ${token}` } };
  return head;
};

export default {
  getUserList: () => {
    return axios.get(
      `${config.URL_END_POINT}/account/business/${config.businessId}/list`
    );
  },
  getUserById: (id) => {
    return axios.get(`${config.URL_END_POINT}/account/${id}`);
  },
  updateUserInfo: (id, data) => {
    return axios.put(`${config.URL_END_POINT}/account/update/${id}`, data);
  },
  updateUserPassword: (id, data) => {
    return axios.put(`${config.URL_END_POINT}/account/${id}/pwd/update`, data);
  },

  //   customer services

  createCustomer: (data) => {
    return axios.post(`${config.URL_END_POINT}/customer/add`, data);
  },
  getCustomerById: (id) => {
    return axios.get(`${config.URL_END_POINT}/customer/${id}`);
  },
  updateCustomerInfo: (id, data) => {
    return axios.put(`${config.URL_END_POINT}/customer/update/${id}`, data);
  },
};
