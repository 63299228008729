import React, { Component } from 'react'
import {
  Row,
  Col,
  Image,
  Form,
  Button,
  ListGroup,
  Spinner,
} from 'react-bootstrap'
import { Animated } from 'react-animated-css'
import { Link } from 'react-router-dom'
import Card from '../../../components/Card'
import Logo from '../../../components/partials/components/logo'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// RestApi service import
import auth from '../../../restApiService/auth'

import hero01 from '../../../assets/images/pages/eduardo-soares-GI5guEoMEgI-unsplash.jpg'
import hero02 from '../../../assets/images/pages/shutterstock_2060136140.png'

// img
import facebook from '../../../assets/images/brands/fb.svg'
import google from '../../../assets/images/brands/gm.svg'
import instagram from '../../../assets/images/brands/im.svg'
import linkedin from '../../../assets/images/brands/li.svg'
import auth1 from '../../../assets/images/auth/01.png'

class Profile extends Component {
  state = {
    item: [],
    handleLoginLoading: false,
  }

  render() {
    const { handleLoginLoading } = this.state
    return (
      <>
        <section className="login-content">
          <Row className="m-0 align-items-center bg-dark vh-100">
            <Col
              md="6"
              className="profile d-md-block d-none bg-dark p-0 mt-n1 vh-100 overflow-hidden"
            >
              <div className="overlay">&nbsp;</div>
              <Image
                // src={auth1}
                src={hero01}
                className="Image-fluid gradient-main animated-scaleX"
                alt="images"
              />
              {/* <Animated
                animationIn={'bounceInLeft'}
                animationOut="fadeOut"
                animationInDelay={300}
                isVisible={true}
              > */}
              <div className="content-above">
                <h2>Je souhaite composer une musique pour un proche</h2>
                <Link
                  to="/auth/customer/sign-up"
                  className="sz-btn-sm bg-red mr-2"
                >
                  Je m'inscrire
                </Link>
              </div>
              {/* </Animated> */}
            </Col>

            <Col
              md="6"
              className="profile d-md-block d-none bg-dark p-0 mt-n1 vh-100 overflow-hidden"
            >
              <div className="overlay">&nbsp;</div>
              <Image
                // src={auth1}
                src={hero02}
                className="Image-fluid gradient-main animated-scaleX"
                alt="images"
              />
              {/* <Animated
                animationIn={'bounceInLeft'}
                animationOut="fadeOut"
                animationInDelay={300}
                isVisible={true}
              > */}
              <div className="content-above">
                <h2>
                  Vous êtes un artiste <br></br>Rejoignez notre communauté
                  {/* <br></br>
                    Écrivez et enregistrez des chansons personnalisées,
                    élargissez votre base de fans et gagnez un revenu réel -
                    rejoignez la communauté d'artistes Sanza ! */}
                </h2>
                <Link
                  to="/auth/artist/sign-up"
                  className="sz-btn-sm bg-red mr-2"
                >
                  Je m'inscrire
                </Link>
              </div>
              {/* </Animated> */}
            </Col>
          </Row>
        </section>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </>
    )
  }
}

export default Profile
