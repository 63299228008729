import React, { Component } from "react";
import {
  Row,
  Col,
  Image,
  Form,
  Button,
  ListGroup,
  Spinner,
} from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Card from "../../../components/Card";
import Logo from "../../../components/partials/components/logo";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// RestApi service import
import auth from "../../../restApiService/auth";
// import depot from '../../../restApiService/depot'

import hero01 from "../../../assets/images/pages/2205_w039_n003_238b_p1_238.png";

// img
import facebook from "../../../assets/images/brands/fb.svg";
import google from "../../../assets/images/brands/gm.svg";
import instagram from "../../../assets/images/brands/im.svg";
import linkedin from "../../../assets/images/brands/li.svg";
import auth1 from "../../../assets/images/auth/01.png";

import {
  addUserInfos,
  removeUserInfos,
} from "../../../store/actions/userActions";

class SignIn extends Component {
  state = {
    item: [],
    handleLoginLoading: false,
  };

  handleChange = (event) => {
    console.log(event.target.name);
    let item = { ...this.state.item };
    item[event.target.name] = event.target.value;
    this.setState({ item });
  };

  handleLogin = () => {
    const promiseVar = toast.loading(
      "Recherche des information du client en cours..."
    );
    this.setState({ handleLoginLoading: true });
    const { item } = this.state;
    auth
      .signIn(item)
      .then((response) => {
        let data = {
          data: response.data.user,
          token: response.data.accessToken,
        };
        this.props.addUserInfos(data);

        toast.update(promiseVar, {
          render: "Connexion effectuée avec succès",
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setTimeout(() => {
          this.setState({ handleLoginLoading: false });
          this.props.history.push("/admin/dashboard");
        }, 1500);
      })
      .catch((error) => {
        toast.update(promiseVar, {
          render: "Informations incorrectes",
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        this.setState({ handleLoginLoading: false });
      });
  };

  render() {
    const { handleLoginLoading } = this.state;
    return (
      <>
        <section className="login-content st-bg-yellow">
          <div class="context">
            <Row className="m-0 align-items-center vh-100">
              <Col md="4">
                <Row>
                  <Col md="12">
                    <Card className="login-content-box card-transparent st-bg-white shadow-none d-flex justify-content-center mb-0 auth-card">
                      <Card.Body>
                        <Link
                          to="/"
                          className="navbar-brand login d-flex align-items-center mb-3"
                        >
                          <Logo />
                        </Link>
                        <h2 className="mb-2">Se connecter</h2>

                        <Form className="login-form">
                          <Row>
                            <Col lg="12">
                              <Form.Group className="form-group">
                                <Form.Label htmlFor="email" className="">
                                  Email
                                </Form.Label>
                                <Form.Control
                                  type="email"
                                  className=""
                                  id="email"
                                  name="email"
                                  onChange={this.handleChange}
                                  aria-describedby="email"
                                  placeholder=" "
                                />
                              </Form.Group>
                            </Col>
                            <Col lg="12" className="">
                              <Form.Group className="form-group">
                                <Form.Label htmlFor="password" className="">
                                  Password
                                </Form.Label>
                                <Form.Control
                                  type="password"
                                  className=""
                                  id="password"
                                  name="password"
                                  onChange={this.handleChange}
                                  aria-describedby="password"
                                  placeholder=" "
                                />
                                <Form.Control.Feedback tooltip type="invalid">
                                  Please provide a valid zip.
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col
                              lg="12"
                              className="d-flex justify-content-between"
                            >
                              <Form.Check className="form-check mb-3">
                                <Form.Check.Input
                                  type="checkbox"
                                  id="customCheck1"
                                />
                                <Form.Check.Label htmlFor="customCheck1">
                                  Se souvenir de moi
                                </Form.Check.Label>
                              </Form.Check>
                              {/* <Link to="/auth/recoverpw">
                                Mot de passe oublié ?
                              </Link> */}
                            </Col>
                          </Row>
                          <div className="d-flex justify-content-center">
                            {handleLoginLoading ? (
                              <button
                                className="st-btn-md st-bg-black w-100 text-white"
                                disabled
                              >
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />{" "}
                                Connexion...
                              </button>
                            ) : (
                              <button
                                onClick={this.handleLogin}
                                className="st-btn-md st-bg-yellow w-100 text-black f-w-600"
                              >
                                Se connecter
                              </button>
                            )}
                          </div>
                        </Form>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div class="area">
            <img
              src={hero01}
              className="Image-fluid gradient-main animated-scaleX"
              alt=""
            />
            {/* <ul class="circles">
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul> */}
          </div>
        </section>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  let addedItemsTmp = state.userInfoReducer;
  return {
    items: addedItemsTmp.addedUserInfos,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addUserInfos: (item) => {
      dispatch(addUserInfos(item));
    },
    removeUserInfos: (item) => {
      dispatch(removeUserInfos(item));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
