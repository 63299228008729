import {
  IS_LOGGED,
  IS_DISCONNECTED,
} from '../actions/action-types/cart-actions'
const initState = {
  user: [],
  addedUserInfos: localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : [],
}
const userInfoReducer = (state = initState, action) => {
  //INSIDE HOME COMPONENT
  if (action.type === IS_LOGGED) {
    let addedUserInfos = null
    addedUserInfos = action.item
    setTimeout(() => {
      localStorage.setItem('userInfo', JSON.stringify(addedUserInfos))
    }, 1000)
    return {
      ...state,
      addedUserInfos: addedUserInfos,
    }
  }
  if (action.type === IS_DISCONNECTED) {
    setTimeout(() => {
      localStorage.removeItem('userInfo')
    }, 1000)
    return {
      ...state,
      addedUserInfos: [],
    }
  } else {
    return state
  }
}
export default userInfoReducer
