import React, { Component } from "react";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import { Row, Col, Image, Form, ListGroup, Spinner } from "react-bootstrap";
import Slider from "react-slick";

import Accordion from "react-bootstrap/Accordion";
import { TypeAnimation } from "react-type-animation";
import { Animated } from "react-animated-css";
// import SkeletonLoading from '../../components/skeletonLoading/skeleton-style-01'
import table_preview from "../../assets/images/icons/take-away-pana.png";
import messenger from "../../assets/images/icons/messenger-pana.png";
import livomockups from "../../assets/images/icons/livomockups.png";
import playStore from "../../assets/images/icons/google_play.png";
import appStore from "../../assets/images/icons/app_store.png";
import takeAway from "../../assets/images/icons/take-away-cuate.png";

class index extends Component {
  state = {
    dataSending: false,
    planId: 0,
  };

  componentDidMount = async () => {};
  componentDidMount() {}

  choicePlan = (planId, name, price) => {
    this.setState({ dataSending: true, planId: planId });
    localStorage.setItem("planId", planId);
    localStorage.setItem("choicePlan", name);
    localStorage.setItem("PlanPrice", price);

    setTimeout(() => {
      this.setState({ dataSending: false });
      this.props.history.push("/auth/sign-up");
    }, 2000);
  };

  render() {
    const { planId, dataSending } = this.state;
    return (
      <>
        {/* <PageLoader /> */}
        <div className="bg-white">
          {/* <Navbars /> */}

          {/* banner section */}
          <div className="banner-section st-bg-yellow">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <Animated
                    animationIn="zoomIn"
                    animationOut="fadeOut"
                    animationInDelay={300}
                    isVisible={true}
                  >
                    <div className="b-content">
                      <p className="b-content-title text-white f-58 f-bold">
                        Besoin d'envoyer quelque chose à temps? Bienvenue
                      </p>
                      <p className="b-content-title text-white f-58 f-bold">
                        sur{" "}
                        <TypeAnimation
                          sequence={[
                            "Livo", // Types 'One'
                            1000, // Waits 1s
                            " ",
                            2000, // Waits 2s
                            "Livo", // Deletes 'One' and types 'Two'
                            () => {
                              console.log("Done typing!"); // Place optional callbacks anywhere in the array
                            },
                          ]}
                          wrapper="span"
                          cursor={true}
                          repeat={Infinity}
                          style={{
                            fontSize: "58px",
                            "text-decoration": "underline",
                            color: "#000",
                          }}
                        />
                      </p>
                    </div>
                  </Animated>
                </div>
                <div className="col-md-6">
                  <Animated
                    animationIn="bounceInUp"
                    animationOut="fadeOut"
                    animationInDelay={1000}
                    isVisible={true}
                  >
                    <div className="b-illustration">
                      <img src={table_preview} alt="" />
                    </div>
                  </Animated>
                </div>
              </div>
            </div>
          </div>
          {/* eof banner section */}
          {/* section 01 */}
          <div
            className="st-bg-grey-most-light ld-feature text-black"
            id="features"
          >
            <div className="container">
              <Animated
                animationIn="bounceInLeft"
                animationOut="fadeOut"
                animationInDelay={1000}
                isVisible={true}
              >
                <h2 className="section-title">Pourquoi nous choisir ?</h2>
              </Animated>

              <Animated
                animationIn="bounceInRight"
                animationOut="fadeOut"
                animationInDelay={1000}
                isVisible={true}
              >
                <div className="row">
                  <div className="col-md-4 col-sm-6">
                    <div className="feature-wrap">
                      <div className="feature-wrap-icon">
                        <i className="fa fa-users"></i>
                      </div>
                      <div className="feature-wrap-content d-block overflow-hidden">
                        <h4>Livraison rapide</h4>
                        <p>
                          Presque comme l'éclair... Le livreur vient récupérer
                          le colis dans les 15 minutes et le livre immédiatement
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-sm-6">
                    <div className="feature-wrap">
                      <div className="feature-wrap-icon">
                        <i className="fa fa-archive"></i>
                      </div>
                      <div className="feature-wrap-content d-block overflow-hidden">
                        <h4>Toutes tailles</h4>
                        <p>
                          Du plus petit au plus volumineux. On ne le dira jamais
                          assez, c'est pas la taille qui compte.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-sm-6">
                    <div className="feature-wrap">
                      <div className="feature-wrap-icon">
                        <i className="fa fa-car"></i>
                      </div>
                      <div className="feature-wrap-content d-block overflow-hidden">
                        <h4>Locale</h4>
                        <p>
                          Des centaines de coursiers à proximité dans un rayon
                          de 30km du point de retrait.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Animated>
            </div>
          </div>
          <div className="bg-white ld-package" id="pricing">
            <div className="container">
              <Animated
                animationIn="bounceInLeft"
                animationOut="fadeOut"
                animationInDelay={1000}
                isVisible={true}
              >
                <h2 className="section-title text-black mb-5">
                  Découvrez la simplicité avec LIVO
                </h2>
              </Animated>

              <div className="container">
                <div className="row">
                  <div className="col-md-4">
                    <Animated
                      animationIn="bounceInUp"
                      animationOut="fadeOut"
                      animationInDelay={1000}
                      isVisible={true}
                    >
                      <div className="b-illustration">
                        <img src={messenger} alt="" />
                      </div>
                    </Animated>
                  </div>
                  <div className="col-md-8">
                    <Animated
                      animationIn="zoomIn"
                      animationOut="fadeOut"
                      animationInDelay={300}
                      isVisible={true}
                    >
                      <div className="b-content p-10 f-18">
                        <p className="b-content-title text-black f-bold">
                          Envoyer et recevoir un colis est désormais comme un
                          jeu d'enfant
                        </p>
                        <div className="b-action mt-4">
                          <ol>
                            <li>
                              Cliquez sur envoyer un colis puis remplissez les
                              détails de la livraison
                            </li>
                            <li>
                              Attendez qu'un livreur vienne chercher votre colis
                            </li>
                            <li>Remettez le(s) colis au livreur</li>
                            <li>
                              Et voilà ! Le livreur est en route pour la
                              livraison
                            </li>
                            <li>
                              Le destinataire recois son colis en toute
                              tranquilité
                            </li>
                          </ol>
                        </div>
                      </div>
                    </Animated>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="bg-green-most-light store-box ld-package"
            id="pricing"
          >
            <div className="container">
              <div className="container">
                <div className="row">
                  <div className="col-md-8">
                    <Animated
                      animationIn="zoomIn"
                      animationOut="fadeOut"
                      animationInDelay={300}
                      isVisible={true}
                    >
                      <div className="b-content p-10">
                        <p className="text-black">
                          Pour une meilleure experience, nous avons pensé à
                          tout.
                        </p>
                        <h4 className="b-content-title text-black f-bold f-32">
                          Téléchargez l'application mobile LIVO
                        </h4>
                        <div className="b-action mt-4">
                          <ul>
                            <li>
                              <a
                                href="https://play.google.com/store/apps/details?id=com.marcco.livo&pcampaignid=web_share"
                                target="BLANK_"
                                className="m-0 p-0 shadow-none"
                              >
                                <img src={playStore} alt="" />
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://apps.apple.com/us/app/livo-delivery/id6504584059"
                                target="BLANK_"
                                className="m-0 p-0 shadow-none"
                              >
                                <img src={appStore} alt="" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Animated>
                  </div>
                  <div className="col-md-4">
                    <Animated
                      animationIn="bounceInUp"
                      animationOut="fadeOut"
                      animationInDelay={1000}
                      isVisible={true}
                    >
                      <div className="b-illustration">
                        <img src={livomockups} alt="" />
                      </div>
                    </Animated>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white ld-package" id="deliver">
            <div className="container">
              <Animated
                animationIn="bounceInLeft"
                animationOut="fadeOut"
                animationInDelay={1000}
                isVisible={true}
              >
                <h2 className="section-title text-black mb-2">
                  Devenez livreur sur LIVO
                </h2>
                <p className="text-black mb-5 subTitle">
                  Devenez livreur avec Livo et Livrez des commandes de
                  restaurants et de boutiques en ligne, décidez de vos revenus
                </p>
              </Animated>

              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <Animated
                      animationIn="zoomIn"
                      animationOut="fadeOut"
                      animationInDelay={300}
                      isVisible={true}
                    >
                      <div className="b-content p-10">
                        <p className="b-content-title text-black f-bold">
                          De quoi avez-vous besoin pour être livreur
                        </p>
                        <p className="b-content-title text-black f-bold">
                          sur LIVO ?
                        </p>
                        <div className="b-action mt-4">
                          <ol>
                            <li>Un deux-roues ou autre</li>
                            <li>
                              Un permis de conduire correspondant au véhicule
                              utilisé
                            </li>
                            <li>Avoir au moins 18 ans</li>
                            <li>Un smartphone Android ou iOS</li>
                          </ol>
                        </div>
                        <div className="b-action">
                          <HashLink
                            smooth
                            to="/auth/sign-up"
                            className="st-btn-md st-bg-yellow text-white p-h-2"
                          >
                            Devenir livreur
                          </HashLink>
                        </div>
                      </div>
                    </Animated>
                  </div>
                  <div className="col-md-6">
                    <Animated
                      animationIn="bounceInUp"
                      animationOut="fadeOut"
                      animationInDelay={1000}
                      isVisible={true}
                    >
                      <div className="b-illustration">
                        <img src={takeAway} alt="" />
                      </div>
                    </Animated>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-black ld-package" id="contact">
            <div className="container">
              <Animated
                animationIn="bounceInLeft"
                animationOut="fadeOut"
                animationInDelay={1000}
                isVisible={true}
              >
                <h2 className="section-title text-center text-white mb-5">
                  Contactez-nous
                </h2>
              </Animated>
              <div className="container">
                <div className="row">
                  <div className="col-md-8 offset-md-2">
                    <Animated
                      animationIn="zoomIn"
                      animationOut="fadeOut"
                      animationInDelay={300}
                      isVisible={true}
                    >
                      <form>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="name">
                            Nom et prénom
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            id="name"
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="email">
                            Email
                          </label>
                          <input
                            className="form-control"
                            type="email"
                            id="email"
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="message">
                            Message
                          </label>
                          <textarea
                            className="form-control"
                            id="message"
                            required
                          />
                        </div>
                        <div className="mb-3 text-center">
                          <button
                            className="st-btn-md st-bg-yellow text-white p-h-50"
                            type="submit"
                          >
                            Envoyer
                          </button>
                        </div>
                      </form>
                    </Animated>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default index;
