//router
import IndexRouters from './router/index'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

//scss
import './assets/scss/hope-ui.scss'
import './assets/scss/dark.scss'
import './assets/scss/rtl.scss'
import './assets/scss/custom.scss'
import './assets/scss/customizer.scss'
import './assets/css/custom.css'
import './assets/css/rwd.css'
import './assets/css/auth-page.css'
import './assets/css/landing-page.css'
import './assets/css/input.css'
import './assets/css/searchform.css'
import './assets/css/footer.css'
import './assets/css/loader.css'
import './assets/css/panneaux.css'
import './assets/css/skeleton-loading.css'
import './assets/css/skeleton-loading-01.css'
import './assets/css/skeleton-loading-03.css'
import './assets/css/skeleton-loading-04.css'
import './assets/css/skeleton-loading-05.css'
import './assets/css/single-espaces.css'
import './assets/css/cart.css'
import './assets/css/mediaplayer.css'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import 'rsuite/dist/rsuite.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

function App() {
  return (
    <div className="App">
      <IndexRouters />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  )
}

export default App
