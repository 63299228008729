import React, { Component } from "react";
import Moment from "react-moment";
import moment from "moment";
import { Row, Col, Dropdown, Button } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import { Input, InputGroup, Whisper, Tooltip, Modal, Drawer } from "rsuite";
import { bindActionCreators } from "redux";
//circular
import Circularprogressbar from "../../components/circularprogressbar.js";

// AOS
import AOS from "aos";
import "../../../node_modules/aos/dist/aos";
import "../../../node_modules/aos/dist/aos.css";
//apexcharts
import Chart from "react-apexcharts";

//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/components/navigation/navigation.scss";
//img
import Empty from "../../components/Empty/Empty";

//Count-up
import CountUp from "react-countup";
// store
import {
  NavbarstyleAction,
  getDirMode,
  getcustomizerMode,
  getcustomizerprimaryMode,
  getcustomizerinfoMode,
  SchemeDirAction,
  ColorCustomizerAction,
  getNavbarStyleMode,
  getSidebarActiveMode,
  SidebarActiveStyleAction,
  getDarkMode,
  ModeAction,
  SidebarColorAction,
  getSidebarColorMode,
  getSidebarTypeMode,
} from "../../store/setting/setting";

import delivery from "../../restApiService/delivery";
import deliver from "../../restApiService/auth";
import { connect } from "react-redux";

class Index extends Component {
  state = {
    dataList: [],
    selectedItem: [],
    itemDetail: [],
    deliverData: 0,
    providerData: 0,
    detailModalIsOpen: false,
    pendingData: 0,
    deletedData: 0,
  };

  coursesList() {
    const { data, token } = this.props.currrentUser;
    delivery.deliveryList(token).then((req) => {
      const pending = req.data.filter(
        (item) => item.status == 1 && item.isCurrent == 1
      );
      const deleted = req.data.filter((item) => item.status == 2);
      this.setState({
        dataList: req.data,
        pendingData: pending.length,
        deletedData: deleted.length,
      });
    });
  }
  deliverList() {
    const { data, token } = this.props.currrentUser;
    deliver.userInfoList(token).then((req) => {
      const deliver = req.data.filter((item) => item.livoProfilTypeId == 2);
      const provider = req.data.filter((item) => item.livoProfilTypeId == 1);
      this.setState({
        deliverData: deliver.length,
        providerData: provider.length,
      });
    });
  }

  detailModal = (item) => {
    this.setState({
      detailModalIsOpen: true,
      selectedItem: item,
      itemDetail: item.livo_packages,
    });
  };

  componentDidMount() {
    this.coursesList();
    this.deliverList();
  }

  render() {
    const { pendingData, deletedData, deliverData, providerData } = this.state;
    const hideModal = () => {
      this.setState({ detailModalIsOpen: false });
    };
    return (
      <>
        <Row>
          <Col md="12" lg="12">
            <Row className="row-cols-1">
              <div className="overflow-hidden d-slider1 ">
                <Swiper
                  className="p-0 m-0 mb-2 list-inline "
                  slidesPerView={5}
                  spaceBetween={32}
                  navigation={{
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                  }}
                  breakpoints={{
                    320: { slidesPerView: 1 },
                    550: { slidesPerView: 2 },
                    991: { slidesPerView: 3 },
                    1400: { slidesPerView: 4 },
                    1500: { slidesPerView: 5 },
                    1920: { slidesPerView: 6 },
                    2040: { slidesPerView: 7 },
                    2440: { slidesPerView: 8 },
                  }}
                >
                  <SwiperSlide className=" card card-slide">
                    <div className="card-body">
                      <div className="progress-widget">
                        <Circularprogressbar
                          stroke={"#fce152"}
                          width="60px"
                          height="60px"
                          trailstroke="#ddd"
                          strokewidth="4px"
                          Linecap="rounded"
                          style={{ width: 60, height: 60 }}
                          value={60}
                          id="circle-progress-04"
                        >
                          <svg
                            className=""
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="currentColor"
                              d="M5,17.59L15.59,7H9V5H19V15H17V8.41L6.41,19L5,17.59Z"
                            />
                          </svg>
                        </Circularprogressbar>
                        <div className="progress-detail">
                          <p className="mb-2">Commandes en cours</p>
                          <h4 className="counter">
                            <CountUp start={0} end={pendingData} duration={1} />
                          </h4>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="card card-slide">
                    <div className="card-body">
                      <div className="progress-widget">
                        <Circularprogressbar
                          stroke={"#000000"}
                          width="60px"
                          height="60px"
                          Linecap="rounded"
                          trailstroke="#ddd"
                          strokewidth="4px"
                          style={{ width: 60, height: 60 }}
                          value={90}
                          id="circle-progress-01"
                        >
                          <svg
                            className=""
                            width="24"
                            height="24px"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="currentColor"
                              d="M5,17.59L15.59,7H9V5H19V15H17V8.41L6.41,19L5,17.59Z"
                            />
                          </svg>
                        </Circularprogressbar>
                        <div className="progress-detail">
                          <p className="mb-2">Commandes annulées</p>
                          <h4 className="counter">
                            <CountUp start={0} end={deletedData} duration={1} />
                          </h4>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className=" card card-slide">
                    <div className="card-body">
                      <div className="progress-widget">
                        <Circularprogressbar
                          stroke={"#fce152"}
                          width="60px"
                          height="60px"
                          trailstroke="#ddd"
                          strokewidth="4px"
                          Linecap="rounded"
                          style={{ width: 60, height: 60 }}
                          value={60}
                          id="circle-progress-02"
                        >
                          <svg
                            className=""
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="currentColor"
                              d="M19,6.41L17.59,5L7,15.59V9H5V19H15V17H8.41L19,6.41Z"
                            />
                          </svg>
                        </Circularprogressbar>
                        <div className="progress-detail">
                          <p className="mb-2">Livreurs</p>
                          <h4 className="counter">
                            <CountUp start={0} end={deliverData} duration={1} />
                          </h4>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className=" card card-slide">
                    <div className="card-body">
                      <div className="progress-widget">
                        <Circularprogressbar
                          stroke={"#000000"}
                          width="60px"
                          height="60px"
                          trailstroke="#ddd"
                          strokewidth="4px"
                          Linecap="rounded"
                          style={{ width: 60, height: 60 }}
                          value={70}
                          id="circle-progress-03"
                        >
                          <svg className="" width="24" viewBox="0 0 24 24">
                            <path
                              fill="currentColor"
                              d="M19,6.41L17.59,5L7,15.59V9H5V19H15V17H8.41L19,6.41Z"
                            />
                          </svg>
                        </Circularprogressbar>
                        <div className="progress-detail">
                          <p className="mb-2">Fournisseurs</p>
                          <h4 className="counter">
                            <CountUp
                              start={0}
                              end={providerData}
                              duration={1}
                            />
                          </h4>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </Row>
          </Col>
          <Col md="12" lg="12">
            <Row>
              <Col md="12" lg="12">
                <div className="overflow-hidden card">
                  <div className="flex-wrap card-header d-flex justify-content-between">
                    <div className="header-title">
                      <h4 className="mb-2 card-title">
                        Listes des courses ({this.state.dataList.length})
                      </h4>
                    </div>
                  </div>
                  <div className="p-0 card-body">
                    <div className="mt-4 table-responsive">
                      <table
                        id="basic-table"
                        className="table mb-0 table-striped"
                        role="grid"
                      >
                        <thead>
                          <tr>
                            <th className="border-0">ID</th>
                            <th className="border-0">Code</th>
                            <th className="border-0">Fournisseurs</th>
                            <th className="border-0">Livreur</th>
                            <th className="border-0">Status</th>
                            <th className="border-0">Date de création</th>
                            <th className="border-0"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.dataList.map((data, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td> {data.code} </td>
                              <td>
                                {data.provider.first_name +
                                  " " +
                                  data.provider.last_name}
                              </td>
                              <td>
                                {data.deliver
                                  ? data.deliver?.first_name +
                                    " " +
                                    data.deliver?.last_name
                                  : "-"}
                              </td>
                              <td>
                                {data.livoDeliverId == null && "-"}
                                {data.status == 1 && data.isCurrent == 1 && (
                                  <span className="pending">En cours</span>
                                )}
                                {data.status == 2 && (
                                  <span className="failure">Annulé</span>
                                )}
                                {data.status == 1 && data.isCurrent == 0 && (
                                  <span className="end">Terminé</span>
                                )}
                              </td>
                              <td>
                                <Moment format="DD/MM/YYYY">
                                  {data.createdAt}
                                </Moment>
                              </td>
                              <td>
                                <button
                                  className="like-link"
                                  onClick={() => this.detailModal(data)}
                                >
                                  Détails
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {this.state.dataList.length < 1 && <Empty />}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Modal
          open={this.state.detailModalIsOpen}
          onClose={hideModal}
          size="lg"
        >
          <Modal.Header>
            <Modal.Title>LIVO ADMIN | Détails de commande</Modal.Title>
          </Modal.Header>
          <Modal.Body className="term">
            <div className="detail-body starter-kit p-4">
              <Accordion>
                {this.state.itemDetail.map((item, index) => (
                  <Accordion.Item eventKey={index}>
                    <Accordion.Header>
                      <div className="delivery-item-header">
                        <p>
                          <strong>Code : </strong>
                          {item.code}{" "}
                        </p>
                        <p>
                          <strong>
                            Type de colis : {item.livo_package_type.title}{" "}
                          </strong>
                        </p>
                        <p>
                          <strong>Statut : </strong>
                          {item.isCurrent == 1 && <span>En cours</span>}
                          {item.isCurrent == 2 && <span>En pause</span>}
                          {item.status == 2 && <span>Annulée</span>}
                          {item.status == 3 && <span>Client Injoignable</span>}
                          {item.status == 4 && <span>Colis refusé</span>}
                          {item.status == 5 && <span>Livré</span>}
                        </p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="delivery-content">
                        <p>
                          <strong>Quantité : </strong> {item.quantity}
                        </p>
                        <p>
                          <strong>Point de ramassage : </strong>{" "}
                          {item.pick_up_point}{" "}
                        </p>
                        <p>
                          <strong>Point de livraison : </strong>{" "}
                          {item.delivery_point}
                        </p>
                        <p>
                          <strong>Poids : </strong>{" "}
                          {item.livo_package_weight.title}
                        </p>
                        <p>
                          <strong>Nom du client : </strong>{" "}
                          {item.recipient_full_name}
                        </p>
                        <p>
                          <strong>Contact : </strong> {item.recipient_contact}
                        </p>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log("state", state);
  return {
    currrentUser: state.userInfoReducer.addedUserInfos,
    darkMode: getDarkMode(state),
    customizerMode: getcustomizerMode(state),
    cololrinfomode: getcustomizerinfoMode(state),
    colorprimarymode: getcustomizerprimaryMode(state),
    schemeDirMode: getDirMode(state),
    sidebarcolorMode: getSidebarColorMode(state),
    sidebarTypeMode: getSidebarTypeMode(state),
    sidebaractivestyleMode: getSidebarActiveMode(state),
    navbarstylemode: getNavbarStyleMode(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      ModeAction,
      SchemeDirAction,
      SidebarColorAction,
      SidebarActiveStyleAction,
      NavbarstyleAction,
      ColorCustomizerAction,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
