import React, { Component, Suspense } from "react";
//router
import { Switch, Route } from "react-router";
// import Index from '../views/dashboard/index'
import Index from "../views/customer-interface";

class HorizontalRouter extends Component {
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.history.history.location.pathname !==
      prevProps.history.location.pathname
    ) {
      window.scrollTo({ top: 0, behavior: "auto" });
    }
  }
  render() {
    return (
      // <div>
      //   <Index />
      // </div>
      <>
        <Suspense fallback={"loading..."}>
          <Switch>
            <Route path="/" exact component={Index}></Route>
            <Route path="/app" exact component={Index}></Route>
          </Switch>
        </Suspense>
      </>
    );
  }
}

export default HorizontalRouter;
