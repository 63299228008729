import axios from "axios";
import config from "./config";

//add token in headers
const headersConfig = (token) => {
  const head = { headers: { Authorization: `Bearer ${token}` } };
  return head;
};

export default {
  getPromotion: (token) => {
    return axios.get(`${config.URL_END_POINT}/promotion`, headersConfig(token));
  },
  createPromotion: (token, data) => {
    return axios.post(
      `${config.URL_END_POINT}/promotion/add`,
      data,
      headersConfig(token)
    );
  },
  promotionById: (id, token) => {
    return axios.get(
      `${config.URL_END_POINT}/promotion/${id}`,
      headersConfig(token)
    );
  },
  updatePromotion: (id, data, token) => {
    return axios.put(
      `${config.URL_END_POINT}/promotion/update/${id}`,
      data,
      headersConfig(token)
    );
  },
};
