import axios from "axios";
import config from "./config";

const headersConfig = (token) => {
  const head = { headers: { Authorization: `Bearer ${token}` } };
  return head;
};

export default {
  transactionCreate: (data, token) => {
    return axios.post(
      `${config.URL_END_POINT}/transaction/add`,
      data,
      headersConfig(token)
    );
  },
  transactionList: (token) => {
    return axios.get(
      `${config.URL_END_POINT}/transaction/list`,
      headersConfig(token)
    );
  },
  transactionById: (id, token) => {
    return axios.get(
      `${config.URL_END_POINT}/transaction/${id}`,
      headersConfig(token)
    );
  },
};
