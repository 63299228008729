import React, { Component } from "react";
import { connect } from "react-redux";
import GoogleMapReact from "google-map-react";
import {
  Row,
  Col,
  Form,
  Popover,
  OverlayTrigger,
  Carousel,
  Spinner,
} from "react-bootstrap";
import {
  Popover as Popovers,
  Whisper,
  Button,
  Modal,
  DateRangePicker,
} from "rsuite";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import GoogleMapReact, Marker from 'google-map-react'

// RestApi service import
import auth from "../../../restApiService/auth";
import appSettings from "../../../restApiService/appSettings";

class Page extends Component {
  state = {
    appData: [],
    appUpDateData: [],
    box: "",
    updateAction: false,
    handleLoading: false,
  };

  handleChange = (event) => {
    let appData = { ...this.state.appData };
    appData[event.target.name] = ["global_price", "delivery_cost"].includes(
      event.target.name
    )
      ? Number(event.target.value)
      : event.target.value;
    this.setState({
      appData,
      appUpDateData: {
        [event.target.name]: ["global_price", "delivery_cost"].includes(
          event.target.name
        )
          ? Number(event.target.value)
          : event.target.value,
      },
    });
  };

  getAppSettingsInfo = () => {
    // const { data, token } = this.props.currentUserInfo;
    auth.initToken().then((response) => {
      const token = response.data.token;
      appSettings
        .settings(token)
        .then((response) => {
          this.setState({ appData: response.data[0] });
        })
        .catch((error) => {});
    });
  };

  updateUserInfo = (event, cases) => {
    const { token } = this.props.currentUserInfo;
    event.preventDefault();
    this.setState({ handleLoading: true });
    const promiseVar = toast.loading("Mise à jour des information en cours...");

    let appData = { ...this.state.appData };
    // let update_item = appData;

    console.log(this.state.appUpDateData);

    appSettings
      .updateSettings(appData.id, this.state.appUpDateData, token)
      .then((response) => {
        this.setState({ handleLoading: false, updateAction: false, box: "" });
        this.componentDidMount();
        toast.update(promiseVar, {
          render: "Opération effectuée avec succès",
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((error) => {
        toast.update(promiseVar, {
          render: "Une erreur est survenue",
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        this.setState({ handleLoading: false });
      });
  };

  handleAction = (box) => {
    this.setState({ box, updateAction: !this.state.updateAction });
  };

  componentDidMount() {
    this.getAppSettingsInfo();
  }

  render() {
    const { appData, handleLoading, box, updateAction } = this.state;

    return (
      <>
        <section id="tabs" className="user-account-page">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="page-title">Paramètres de configurations</h2>
              </div>
              <div className="col-md-12">
                <div className="info-item-box mt-5">
                  <div className="info-box">
                    <div className="info-box-content">
                      <label>Version de Livo PRO</label>

                      {box === "deliver_version" && updateAction ? null : (
                        <span>
                          {appData.deliver_version
                            ? appData.deliver_version
                            : "Non spécifié"}
                        </span>
                      )}
                    </div>
                    <div className="info-box-action">
                      {box !== "deliver_version" && !updateAction && (
                        <button
                          onClick={() => this.handleAction("deliver_version")}
                        >
                          Modifier
                        </button>
                      )}
                      {box === "deliver_version" && updateAction && (
                        <button onClick={() => this.handleAction("")}>
                          Annuler
                        </button>
                      )}
                    </div>
                  </div>
                  {box === "deliver_version" && updateAction && (
                    <div className="info-edit-box mt-4">
                      <div className="row">
                        <div className="col-md-12">
                          <Form.Group className="form-group">
                            <Form.Control
                              type="text"
                              className=""
                              id="deliver_version"
                              name="deliver_version"
                              value={appData.deliver_version}
                              onChange={this.handleChange}
                              placeholder=" "
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-12">
                          {handleLoading ? (
                            <Button variant="st-bg-yellow w-50" disabled>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />{" "}
                              Enregistrement...
                            </Button>
                          ) : (
                            <Button
                              onClick={(e) =>
                                this.updateUserInfo(e, "deliver_version")
                              }
                              type="button"
                              variant="st-bg-yellow w-50"
                              className="st-bg-yellow"
                            >
                              Enregistrer
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="info-item-box">
                  <div className="info-box">
                    <div className="info-box-content">
                      <label>Version de Livo</label>
                      {box === "provider_version" && updateAction ? null : (
                        <span>{appData.provider_version}</span>
                      )}
                    </div>
                    <div className="info-box-action">
                      {box !== "provider_version" && !updateAction && (
                        <button
                          onClick={() => this.handleAction("provider_version")}
                        >
                          Modifier
                        </button>
                      )}
                      {box === "provider_version" && updateAction && (
                        <button onClick={() => this.handleAction("")}>
                          Annuler
                        </button>
                      )}
                    </div>
                  </div>
                  {box === "provider_version" && updateAction && (
                    <div className="info-edit-box mt-4">
                      <div className="row">
                        <div className="col-md-12">
                          <Form.Group className="form-group">
                            <Form.Control
                              type="text"
                              className=""
                              id="provider_version"
                              name="provider_version"
                              value={appData.provider_version}
                              onChange={this.handleChange}
                              placeholder=" "
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-12">
                          {handleLoading ? (
                            <Button variant="st-bg-yellow w-50" disabled>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />{" "}
                              Enregistrement...
                            </Button>
                          ) : (
                            <Button
                              onClick={(e) => this.updateUserInfo(e, "name")}
                              type="button"
                              variant="st-bg-yellow w-50"
                              className="st-bg-yellow"
                            >
                              Enregistrer
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="info-item-box">
                  <div className="info-box">
                    <div className="info-box-content">
                      <label>Coût de la livrason</label>

                      {box === "global_price" && updateAction ? null : (
                        <span>{appData.global_price ?? 0} FCFA</span>
                      )}
                    </div>
                    <div className="info-box-action">
                      {box !== "global_price" && !updateAction && (
                        <button
                          onClick={() => this.handleAction("global_price")}
                        >
                          Modifier
                        </button>
                      )}
                      {box === "global_price" && updateAction && (
                        <button onClick={() => this.handleAction("")}>
                          Annuler
                        </button>
                      )}
                    </div>
                  </div>
                  {box === "global_price" && updateAction && (
                    <div className="info-edit-box mt-4">
                      <div className="row">
                        <div className="col-md-12">
                          <Form.Group className="form-group">
                            <Form.Control
                              type="number"
                              className=""
                              id="global_price"
                              name="global_price"
                              value={appData.global_price}
                              onChange={this.handleChange}
                              placeholder=" "
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-12">
                          {handleLoading ? (
                            <Button variant="st-bg-yellow w-50" disabled>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />{" "}
                              Enregistrement...
                            </Button>
                          ) : (
                            <Button
                              onClick={(e) =>
                                this.updateUserInfo(e, "global_price")
                              }
                              type="button"
                              variant="st-bg-yellow w-50"
                              className="st-bg-yellow"
                            >
                              Enregistrer
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="info-item-box">
                  <div className="info-box">
                    <div className="info-box-content">
                      <label>Coût d'une livraison pour le livreur </label>

                      {box === "delivery_cost" && updateAction ? null : (
                        <span>{appData.delivery_cost ?? 0} FCFA</span>
                      )}
                    </div>
                    <div className="info-box-action">
                      {box !== "delivery_cost" && !updateAction && (
                        <button
                          onClick={() => this.handleAction("delivery_cost")}
                        >
                          Modifier
                        </button>
                      )}
                      {box === "delivery_cost" && updateAction && (
                        <button onClick={() => this.handleAction("")}>
                          Annuler
                        </button>
                      )}
                    </div>
                  </div>
                  {box === "delivery_cost" && updateAction && (
                    <div className="info-edit-box mt-4">
                      <div className="row">
                        <div className="col-md-12">
                          <Form.Group className="form-group">
                            <Form.Control
                              type="number"
                              className=""
                              id="delivery_cost"
                              name="delivery_cost"
                              value={appData.delivery_cost}
                              onChange={this.handleChange}
                              placeholder=" "
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-12">
                          {handleLoading ? (
                            <Button variant="st-bg-yellow w-50" disabled>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />{" "}
                              Enregistrement...
                            </Button>
                          ) : (
                            <Button
                              onClick={(e) =>
                                this.updateUserInfo(e, "delivery_cost")
                              }
                              type="button"
                              variant="st-bg-yellow w-50"
                              className="st-bg-yellow"
                            >
                              Enregistrer
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUserInfo: state.userInfoReducer.addedUserInfos,
  };
};
const mapDispatchToProps = (dispatch) => {
  return;
};

export default connect(mapStateToProps, mapDispatchToProps)(Page);
