import axios from "axios";
import config from "./config";

export default {
  NewRaceAvailable: (data) => {
    // console.log(data);
    return axios.post(
      `${config.URL_END_POINT}/api/push/new-race-available/`,
      data
    );
  },
  ChangeDeliveryPackageStatus: (data) => {
    // console.log(data);
    return axios.post(
      `${config.URL_END_POINT}/api/push/change-delivery-package-status/`,
      data
    );
  },
  pushIndividualNotifications: (data) => {
    // console.log(data);
    // return axios.post(`${config.URL_END_POINT}/push/new-race-available/`, data);
    return axios.post(
      `${config.PUSH_NOTIFICATION_URL}/indie/notification`,
      data
    );
  },
  NewRaceAvailableGroup: (data) => {
    // console.log(data);
    // return axios.post(`${config.URL_END_POINT}/push/new-race-available/`, data);
    return axios.post(
      `${config.PUSH_NOTIFICATION_URL}/indie/group/notification`,
      data
    );
  },
  RaceIsCancel: (data) => {
    if (data.deliverId != null) {
      return axios.post(
        `${config.URL_END_POINT}/api/push/provider-canceled-race/`,
        data
      );
    }
  },
  DeliverAcceptedRace: (data) => {
    if (data.deliverId != null) {
      return axios.post(
        `${config.URL_END_POINT}/api/push/deliver-accepted-race/`,
        data
      );
    }
  },
};
