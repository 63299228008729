import React, { Component } from 'react'
import { Row, Col, Image, Form, Button, Spinner } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import Card from '../../../components/Card'
import Logo from '../../../components/partials/components/logo'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// RestApi service import
import auth from '../../../restApiService/auth'

// // img
// import auth2 from '../../../assets/images/auth/02.png'
class Recoverpw extends Component {
  state = {
    item: [],
    handleLoginLoading: false,
  }

  handleChange = (event) => {
    console.log(event.target.name)
    let item = { ...this.state.item }
    item[event.target.name] = event.target.value
    this.setState({ item })
  }

  handleLogin = () => {
    const promiseVar = toast.loading(
      'Recherche des information du client en cours...',
    )
    this.setState({ handleLoginLoading: true })
    const { item } = this.state
    auth
      .requestResetPassword(item)
      .then((response) => {
        toast.update(promiseVar, {
          render:
            'Votre demande a été envoyé avec succès. Un e-mail avec des instructions pour réinitialiser votre mot de passe vous a été envoyé',
          type: 'success',
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        })

        setTimeout(() => {
          this.setState({ handleLoginLoading: false })
          this.props.history.push('/auth/sign-in')
        }, 8000)
      })
      .catch((error) => {
        toast.update(promiseVar, {
          render: 'Informations incorrectes',
          type: 'error',
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        })
        this.setState({ handleLoginLoading: false })
      })
  }

  render() {
    const { handleLoginLoading } = this.state
    return (
      <>
        <section className="login-content">
          <Row className="m-0 align-items-center bg-white vh-100">
            <Col
              md="6"
              className="d-md-block d-none bg-primary p-0 mt-n1 vh-100 overflow-hidden"
            >
              <Image
                src="https://nymba-files.s3.us-west-2.amazonaws.com/Photo%202.jpg"
                className="Image-fluid gradient-main animated-scaleX"
                alt="images"
              />
            </Col>
            <Col md="6" className="p-0">
              <Card className="card-transparent auth-card shadow-none d-flex justify-content-center mb-0">
                <Card.Body>
                  <Link
                    to="/app"
                    className="navbar-brand login d-flex align-items-center mb-3"
                  >
                    <Logo />
                  </Link>
                  <h2 className="mb-2">Mot de passe oublié</h2>
                  <p className="mb-4">
                    Entrez votre adresse e-mail et nous vous enverrons un e-mail
                    avec des instructions pour réinitialiser votre mot de passe.
                  </p>
                  <Form>
                    <Row>
                      <Col lg="12" className="col-lg-12">
                        <Form.Group className="floating-label">
                          <Form.Label htmlFor="email" className="form-label">
                            Email
                          </Form.Label>
                          <Form.Control
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            onChange={this.handleChange}
                            aria-describedby="email"
                            placeholder=" "
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    {handleLoginLoading ? (
                      <Button variant="btn btn-yellow w-100" disabled>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />{' '}
                        Envoie en cours...
                      </Button>
                    ) : (
                      <Button
                        onClick={this.handleLogin}
                        type="button"
                        variant="btn btn-yellow w-100"
                      >
                        Envoyer le mot de passe par e-mail
                      </Button>
                    )}
                  </Form>
                </Card.Body>
              </Card>
              {/* <div className="sign-bg sign-bg-right">
              <svg
                width="280"
                height="230"
                viewBox="0 0 431 398"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.05">
                  <rect
                    x="-157.085"
                    y="193.773"
                    width="543"
                    height="77.5714"
                    rx="38.7857"
                    transform="rotate(-45 -157.085 193.773)"
                    fill="#3B8AFF"
                  />
                  <rect
                    x="7.46875"
                    y="358.327"
                    width="543"
                    height="77.5714"
                    rx="38.7857"
                    transform="rotate(-45 7.46875 358.327)"
                    fill="#3B8AFF"
                  />
                  <rect
                    x="61.9355"
                    y="138.545"
                    width="310.286"
                    height="77.5714"
                    rx="38.7857"
                    transform="rotate(45 61.9355 138.545)"
                    fill="#3B8AFF"
                  />
                  <rect
                    x="62.3154"
                    y="-190.173"
                    width="543"
                    height="77.5714"
                    rx="38.7857"
                    transform="rotate(45 62.3154 -190.173)"
                    fill="#3B8AFF"
                  />
                </g>
              </svg>
            </div> */}
            </Col>
          </Row>
        </section>
        <ToastContainer
          position="top-center"
          autoClose={8000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </>
    )
  }
}

export default Recoverpw
