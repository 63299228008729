import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import { addUserInfos, removeUserInfos } from '../store/actions/userActions'

const ProtectedRoute = ({ component: Comp, loggedIn, path, ...rest }) => {
  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        return localStorage.getItem('userInfo') ? (
          <Comp {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/auth/sign-in',
              state: {
                prevLocation: path,
                error: 'You need to login first!',
              },
            }}
          />
        )
      }}
    />
  )
}

const mapStateToProps = (state) => {
  let addedItemsTmp = state.userInfoReducer
  return {
    currentUserInfo: addedItemsTmp.addedUserInfos,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    addUserInfos: (item) => {
      dispatch(addUserInfos(item))
    },
    removeUserInfos: (item) => {
      dispatch(removeUserInfos(item))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute)
