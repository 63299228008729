import React, { Component, Suspense } from "react";
//router
import { Switch, Route } from "react-router";
// import Index from '../views/dashboard/index'
import Index from "../views/MaintenanceMode/MaintenanceMode";

class HorizontalRouter extends Component {
  componentDidUpdate(prevProps, prevState) {}
  render() {
    return (
      <>
        <Suspense fallback={"loading..."}>
          <Switch>
            <Route path="/" exact component={Index}></Route>
            <Route path="/app" exact component={Index}></Route>
            <Route path="/app/a-propos" exact component={Index}></Route>
            <Route path="/app/artistes" exact component={Index}></Route>
            <Route path="/app/occasions" exact component={Index}></Route>
            <Route path="/app/pricing" exact component={Index}></Route>
            <Route
              path="/app/basic/track-builder"
              exact
              component={Index}
            ></Route>
            <Route
              path="/app/standard/track-builder"
              exact
              component={Index}
            ></Route>
            <Route
              path="/app/premium/track-builder"
              exact
              component={Index}
            ></Route>
            <Route path="/app/chechout/success" exact component={Index}></Route>
            <Route path="/app/artistes/:slug" exact component={Index}></Route>
            <Route
              path="/app/search-results/:data"
              exact
              component={Index}
            ></Route>
            <Route
              path="/app/espaces/:target/:slug"
              exact
              component={Index}
            ></Route>
            <Route path="/app/song/:type/:slug" exact component={Index}></Route>
            <Route path="/app/espaces/:slug" exact component={Index}></Route>
            <Route path="/app/mon-panier" exact component={Index}></Route>
            <Route
              path="/app/checkout/complete"
              exact
              component={Index}
            ></Route>
            <Route path="/app/favoris" exact component={Index}></Route>
            <Route
              path="/app/account/info-personelles"
              exact
              component={Index}
            ></Route>
          </Switch>
        </Suspense>
      </>
    );
  }
}

export default HorizontalRouter;
