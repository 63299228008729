import {
  ADD_TO_WISH,
  REMOVE_WISH_ITEM,
} from '../actions/action-types/cart-actions'
const initState = {
  wishItems: [],
  addedWishItems: localStorage.getItem('tmpWish')
    ? JSON.parse(localStorage.getItem('tmpWish'))
    : [],
  total: 0,
}
const wishReducer = (state = initState, action) => {
  //INSIDE HOME COMPONENT
  if (action.type === ADD_TO_WISH) {
    const id = action.item.id
    const isOld = state.addedWishItems.map((item) => item.id).includes(id)
    let addedWishItems = null

    if (isOld) {
      console.log('ok')
    } else {
      // addedWishItems = [...state.addedWishItems, action.item]
      addedWishItems = [...state.addedWishItems, action.item]
      setTimeout(() => {
        localStorage.setItem('tmpWish', JSON.stringify(addedWishItems))
      }, 1000)
      return {
        ...state,
        addedWishItems: addedWishItems,
      }
    }
  }
  if (action.type === REMOVE_WISH_ITEM) {
    let itemToRemove = state.addedWishItems.find(
      (item) => action.item.id === item.id,
    )
    let new_items = state.addedWishItems.filter(
      (item) => action.item.id !== item.id,
    )

    //calculating the total
    let newTotal = state.total - itemToRemove.price * itemToRemove.quantity
    setTimeout(() => {
      localStorage.setItem('tmpWish', JSON.stringify(new_items))
    }, 1000)
    return {
      ...state,
      addedWishItems: new_items,
      total: newTotal,
    }
  } else {
    return state
  }
}
export default wishReducer
