import React, { Component } from "react";
import io from "socket.io-client";
import { connect } from "react-redux";
import { Form, Spinner } from "react-bootstrap";
import { Popover as Popovers, Button } from "rsuite";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import GoogleMapReact, Marker from 'google-map-react'

// RestApi service import
import config from "../../../restApiService/config";
import auth from "../../../restApiService/auth";
import annonceServices from "../../../restApiService/promotions";
import pushNotif from "../../../restApiService/pushNotif";

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      annoncesList: [],
      appUpDateData: [],
      updateingItem: [],
      initialDeliverListData: [],
      deliverListData: [],
      notifyData: [],
      box: "",
      updateAction: false,
      handleLoading: false,
    };
    this.socket = io(config.URL_SOCKET_END_POINT);
  }

  handleChange = (event) => {
    let updateingItem = { ...this.state.updateingItem };
    updateingItem[event.target.name] = ["status"].includes(event.target.name)
      ? Number(event.target.value)
      : event.target.value;
    this.setState({ updateingItem });
  };
  handleChangeNotifyData = (event) => {
    let notifyData = { ...this.state.notifyData };
    notifyData[event.target.name] = event.target.value;
    // console.log(event.target.value);
    this.setState({ notifyData });
  };

  getAnnonceList = () => {
    // const { data, token } = this.props.currentUserInfo;
    auth.initToken().then((response) => {
      const token = response.data.token;
      annonceServices
        .getPromotion(token)
        .then((response) => {
          this.setState({ annoncesList: response.data });
        })
        .catch((error) => {});
    });
  };

  updateAnnonceInfo = (event, cases) => {
    const { token } = this.props.currentUserInfo;
    event.preventDefault();
    this.setState({ handleLoading: true });
    const promiseVar = toast.loading("Mise à jour des information en cours...");

    let updateingItem = { ...this.state.updateingItem };
    // let update_item = annoncesList;
    delete updateingItem["createdAt"];
    delete updateingItem["updatedAt"];

    annonceServices
      .updatePromotion(updateingItem.id, updateingItem, token)
      .then((response) => {
        this.setState({ handleLoading: false, updateAction: false, box: "" });
        this.componentDidMount();
        toast.update(promiseVar, {
          render: "Opération effectuée avec succès",
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        this.sendAnnonceNotification();
      })
      .catch((error) => {
        toast.update(promiseVar, {
          render: "Une erreur est survenue",
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        this.setState({ handleLoading: false });
      });
  };

  sendNotification = () => {
    const { title, comment } = this.state.notifyData;

    let pushNotificationData = {
      // subIDs: ["jeanmarcboa@outlook.fr", "manouguinan@gmail.com"],
      subIDs: this.state.deliverListData,
      appId: 4618,
      appToken: "rYIwQOcmhLJ76pEm9smjVw",
      title: "Annonce Livo",
      message: comment,
    };

    pushNotif.NewRaceAvailableGroup(pushNotificationData).catch((error) => {});
  };

  handleAction = (box) => {
    this.setState({ box, updateAction: !this.state.updateAction });
    // add announce info by box value using filter form annoncesList in updateingItem
    const updateingItem = this.state.annoncesList.filter((item) => {
      return item.id === box;
    });
    this.setState({ updateingItem: updateingItem[0] });
  };

  sendAnnonceNotification = () => {
    const data = {
      sender: "admin",
      type: "new-annonce",
      message: "Une nouvelle annonce",
    };
    this.socket.emit("sendPromotion", data);
  };

  getDeliverList = () => {
    const { token } = this.props.currentUserInfo;
    auth
      .userInfoList(token)
      .then((res) => {
        let deliverList = res.data.filter(
          (item) => item.livoProfilTypeId === 1
        );
        this.setState({
          initialDeliverListData: deliverList,
        });
      })
      .catch((err) => {
        console.log("error : ", err);
      });
    auth.userProviderList(token).then((res) => {
      //extract all email in an array

      console.log("ress : ", res.data);

      let deliverListData = res.data.map((item) => item.email);
      auth
        .nativeNotifydeliverList({ emails: deliverListData })
        .then((response) => {
          this.setState({
            deliverListData: response.data,
          });
        });
    });
  };
  componentDidMount() {
    this.getDeliverList();
    this.getAnnonceList();
  }

  componentWillUnmount() {
    this.socket.disconnect();
  }

  render() {
    const {
      annoncesList,
      updateingItem,
      handleLoading,
      box,
      updateAction,
      notifyData,
    } = this.state;

    return (
      <>
        <section id="tabs" className="user-account-page">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="page-title">Envoyer une notification</h2>
              </div>
              <div className="col-md-12">
                <div className="info-item-box mt-5">
                  <div className="info-edit-box mt-4">
                    <div className="row">
                      {/* <div className="col-md-12">
                        <Form.Group className="form-group">
                          <Form.Label className="form-label">Titre</Form.Label>
                          <Form.Control
                            type="text"
                            className=""
                            id="title"
                            name="title"
                            // value={notifyData?.title}
                            onChange={(e) => this.handleChangeNotifyData(e)}
                            placeholder=" "
                          />
                        </Form.Group>
                      </div> */}
                      <div className="col-md-12">
                        <Form.Group className="form-group">
                          <Form.Label className="form-label">
                            Message
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            type="text"
                            className=""
                            id="comment"
                            name="comment"
                            // value={notifyData?.comment}
                            onChange={(e) => this.handleChangeNotifyData(e)}
                            placeholder=" "
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-12">
                        {handleLoading ? (
                          <Button variant="st-bg-yellow w-50" disabled>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />{" "}
                            Patientez...
                          </Button>
                        ) : (
                          <Button
                            onClick={() => this.sendNotification()}
                            type="button"
                            variant="st-bg-yellow w-50"
                            className="st-bg-yellow"
                          >
                            Envoyer
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="tabs" className="user-account-page">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="page-title">Annonces</h2>
              </div>
              <div className="col-md-12">
                {annoncesList.map((item, index) => {
                  return (
                    <div className="info-item-box mt-5" key={index}>
                      <div className="info-box">
                        <div className="info-box-content">
                          <label>{item.title}</label>
                          <p className="mb-2 mt-2">
                            Cible :{" "}
                            <strong>
                              {item.target === "provider"
                                ? "Clients"
                                : "Livreurs"}
                            </strong>{" "}
                            - Statut :{" "}
                            <strong>{item.status ? "Actif" : "Inactif"}</strong>
                          </p>

                          {box === item.id && updateAction ? null : (
                            <span>
                              {item.comment ? item.comment : "Non spécifié"}
                            </span>
                          )}
                        </div>
                        <div className="info-box-action">
                          {box !== item.id && !updateAction && (
                            <button onClick={() => this.handleAction(item.id)}>
                              Modifier
                            </button>
                          )}
                          {box === item.id && updateAction && (
                            <button onClick={() => this.handleAction("")}>
                              Annuler
                            </button>
                          )}
                        </div>
                      </div>
                      {box === item.id && updateAction && (
                        <div className="info-edit-box mt-4">
                          <div className="row">
                            <div className="col-md-12">
                              <Form.Group className="form-group">
                                <Form.Label className="form-label">
                                  Titre
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  className=""
                                  id="title"
                                  name="title"
                                  value={updateingItem.title}
                                  onChange={this.handleChange}
                                  placeholder=" "
                                />
                              </Form.Group>
                            </div>
                            <div className="col-md-6">
                              <Form.Group className="form-group">
                                <Form.Label className="form-label">
                                  Cible
                                </Form.Label>
                                <select
                                  className="form-control"
                                  name="target"
                                  value={updateingItem.target}
                                  onChange={this.handleChange}
                                >
                                  <option value="all"></option>
                                  <option value="provider">Client</option>
                                  <option value="deliver">Livreur</option>
                                </select>
                              </Form.Group>
                            </div>
                            <div className="col-md-6">
                              <Form.Group className="form-group">
                                <Form.Label className="form-label">
                                  Statut
                                </Form.Label>
                                <select
                                  className="form-control"
                                  name="status"
                                  value={updateingItem.status ? "1" : "0"}
                                  onChange={this.handleChange}
                                >
                                  <option value="all"></option>
                                  <option value="1">Activé</option>
                                  <option value="0">Désactivé</option>
                                </select>
                              </Form.Group>
                            </div>
                            <div className="col-md-12">
                              <Form.Group className="form-group">
                                <Form.Label className="form-label">
                                  Commentaire
                                </Form.Label>
                                <Form.Control
                                  as="textarea"
                                  rows={3}
                                  type="text"
                                  className=""
                                  id="comment"
                                  name="comment"
                                  value={updateingItem.comment}
                                  onChange={this.handleChange}
                                  placeholder=" "
                                />
                              </Form.Group>
                            </div>
                            <div className="col-md-12">
                              {handleLoading ? (
                                <Button variant="st-bg-yellow w-50" disabled>
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />{" "}
                                  Enregistrement...
                                </Button>
                              ) : (
                                <Button
                                  onClick={(e) =>
                                    this.updateAnnonceInfo(e, "deliver_version")
                                  }
                                  type="button"
                                  variant="st-bg-yellow w-50"
                                  className="st-bg-yellow"
                                >
                                  Enregistrer
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUserInfo: state.userInfoReducer.addedUserInfos,
  };
};
const mapDispatchToProps = (dispatch) => {
  return;
};

export default connect(mapStateToProps, mapDispatchToProps)(Page);
