import React, { Component } from 'react'
import axios from 'axios'
import { Row, Col, Form, Spinner } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Card from '../../../components/Card'
import Logo from '../../../components/partials/components/logo'
import { Animated } from 'react-animated-css'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// RestApi service import
import auth from '../../../restApiService/auth'
import wallet from '../../../restApiService/wallet'

// img

import hero02 from '../../../assets/images/pages/2205_w039_n003_238b_p1_238.png'

import {
  addUserInfos,
  removeUserInfos,
} from '../../../store/actions/userActions'

class SignUp extends Component {
  state = {
    item: [],
    userInfo: [],
    roleId: [],
    itemTransaction: [],
    handleLoginLoading: false,
    currentStep: 1,
    SelectedFile01: [],
    SelectedFile02: [],
    SelectedFile03: [],
    SelectedFile04: [],
    nextStepIsAble: false,
  }

  handleChange = (event) => {
    let item = { ...this.state.item }
    if (item.cgu) {
      delete item['cgu']
    } else {
      item[event.target.name] = event.target.value
    }
    this.setState({ item })
    console.log('Object.keys(item).length', Object.keys(item).length)
  }

  handleLogin = () => {
    const promiseVar = toast.loading(
      'Recherche des information du client en cours...',
    )

    let item = { ...this.state.item }
    this.setState({ handleLoginLoading: true })

    item['profilTypeId'] = 2
    item['lng_location'] = null
    item['lt_location'] = null
    auth
      .signUp(item)
      .then((res) => {
        // deliver wallet create creation
        let walletItem = {
          solde: 0,
          delivery: 0,
          livoUserId: res.data.user.id,
        }
        wallet
          .createWallet(walletItem)
          .then((res) => {})
          .catch((err) => {})
        this.setState({
          userInfo: res.data.user,
        })
        setTimeout(() => {
          this.updatingImages()
        }, 200)
      })
      .catch((error) => {})
  }

  next = (event) => {
    event.preventDefault()
    // scrollToTop()
    let currentStep = this.state.currentStep
    this.setState({
      currentStep: currentStep + 1,
      nextStep: true,
    })
    // this.mainStep(currentStep + 1)
  }

  previous = (event) => {
    event.preventDefault()
    // scrollToTop()
    let currentStep = this.state.currentStep
    this.setState({
      currentStep: currentStep - 1,
      nextStep: false,
    })
    // this.mainStep(currentStep - 1)
  }

  stepPass = (item, currentStep) => {
    let nextStepIsAble = false
    if (currentStep === 1) {
      if (Object.keys(item).length >= 5) {
        nextStepIsAble = true
      } else nextStepIsAble = false
    }

    if (currentStep === 2) {
      if (Object.keys(item).length >= 10) {
        nextStepIsAble = true
      } else nextStepIsAble = false
    }

    if (currentStep === 3) {
      if (Object.keys(item).length >= 5) {
        nextStepIsAble = true
      } else nextStepIsAble = false
    }

    return nextStepIsAble
  }

  changeHandler = (event) => {
    let item = { ...this.state.item }
    console.log('event.target.name', event.target.name)
    if (event.target.name == 'avatar') {
      item['SelectedFile01'] = 1
      this.setState({
        SelectedFile01: event.target.files[0],
      })
    }
    if (event.target.name == 'ci') {
      item['SelectedFile02'] = 1
      this.setState({
        SelectedFile02: event.target.files[0],
      })
    }
    if (event.target.name == 'carte_grise') {
      item['SelectedFile03'] = 1
      this.setState({
        SelectedFile03: event.target.files[0],
      })
    }
    if (event.target.name == 'immat') {
      item['SelectedFile04'] = 1
      this.setState({
        SelectedFile04: event.target.files[0],
      })
    }
    this.setState({ item })

    console.log('Object.keys(item).length', Object.keys(item).length)
  }

  updatingImages = () => {
    const {
      SelectedFile01,
      SelectedFile02,
      SelectedFile03,
      SelectedFile04,
    } = this.state
    let fileArray = []
    setTimeout(() => {
      fileArray.push({ file: SelectedFile01[0], file_lab: 'avatar' })
    }, 100)
    setTimeout(() => {
      fileArray.push({ file: SelectedFile02[0], file_lab: 'ci' })
    }, 200)
    setTimeout(() => {
      fileArray.push({
        file: SelectedFile03[0],
        file_lab: 'carte_grise',
      })
    }, 400)
    setTimeout(() => {
      fileArray.push({ file: SelectedFile04[0], file_lab: 'immat' })
    }, 600)
    setTimeout(() => {
      for (let i = 0; i < fileArray.length; i++) {
        this.uploadImageAsync(fileArray[i].file, fileArray[i].file_lab)
        if (i === fileArray.length - 1) {
          this.props.history.push('/account/created')
        }
      }
    }, 800)
  }

  uploadImageAsync = (pictureuri, file_lab) => {
    let userInfo = { ...this.state.userInfo }
    console.log('sending')
    let URL_END_POINT = 'https://livo-backend.eu-gb.cf.appdomain.cloud/api'
    // let URL_END_POINT = 'http://192.168.254.84:3000/api';
    let apiUrl = URL_END_POINT + '/account/update/' + userInfo.id
    const data = new FormData()
    data.append('files', pictureuri)

    data.append('file_lab', file_lab)

    console.log('data', data)
    axios({
      method: 'PUT',
      url: apiUrl,
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data; boundary=${form._boundary}',
      },
    })
      // fetch(apiUrl, {
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'multipart/form-data',
      //   },
      //   method: 'PUT',
      //   body: data,
      // })
      .then((response) => {
        // console.log('succ ');
        console.log(response.data)
      })
      .catch((err) => {
        console.log('err ')
        console.log(err)
      })
  }

  componentDidMount() {}

  render() {
    const { handleLoginLoading, currentStep } = this.state
    return (
      <>
        <section className="login-content st-bg-yellow">
          <div class="context">
            <Row className="m-0 align-items-center vh-100">
              <Col md="6">
                <Row className="justify-content-center">
                  <Col md="10">
                    <Card className="login-content-box card-transparent st-bg-white shadow-none d-flex justify-content-center mb-0 auth-card">
                      <Card.Body>
                        <Link
                          to="/"
                          className="navbar-brand d-flex align-items-center mb-5"
                        >
                          <Logo />
                        </Link>
                        <h2 className="mb-2">Créer un compte</h2>
                        <p className="mb-4">
                          Créez un compte afin de profiter des services de{' '}
                          <strong>Livo</strong> en toute simplicité.
                        </p>
                        <Form hidden={currentStep === 1 ? false : true}>
                          <Animated
                            animationIn="bounceInLeft"
                            animationOut="fadeOut"
                            animationInDelay={200}
                            isVisible={true}
                          >
                            <Row>
                              <Col lg="6">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="full-name" className="">
                                    Nom*
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    className=""
                                    name="last_name"
                                    id="_name"
                                    onChange={this.handleChange}
                                    placeholder=" "
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg="6">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="last-name" className="">
                                    Prenom(s)*
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    className=""
                                    name="first_name"
                                    id="last-name"
                                    onChange={this.handleChange}
                                    placeholder=" "
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg="6">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="email" className="">
                                    Adresse email*
                                  </Form.Label>
                                  <Form.Control
                                    type="email"
                                    className=""
                                    id="email"
                                    name="email"
                                    onChange={this.handleChange}
                                    placeholder=" "
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg="6">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="phone" className="">
                                    Numéro de téléphone*
                                  </Form.Label>
                                  <Form.Control
                                    type="number"
                                    className=""
                                    id="phone"
                                    name="phone"
                                    onChange={this.handleChange}
                                    placeholder=" "
                                  />
                                </Form.Group>
                              </Col>
                              {/* <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="password" className="">
                                    Organisation *
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    className=""
                                    id="name"
                                    name="name"
                                    onChange={this.handleChange}
                                    placeholder="Exemple : Marc & Co SARL"
                                  />
                                </Form.Group>
                              </Col> */}
                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="password" className="">
                                    Mot de passe*
                                  </Form.Label>
                                  <Form.Control
                                    type="password"
                                    className=""
                                    id="password"
                                    name="password"
                                    onChange={this.handleChange}
                                    placeholder=" "
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                            <div className="d-flex justify-content-end">
                              <button
                                onClick={this.next}
                                disabled={
                                  this.stepPass(
                                    this.state.item,
                                    this.state.currentStep,
                                  )
                                    ? false
                                    : true
                                }
                                className="st-btn-md st-bg-yellow text-black f-w-600 p-h-20"
                              >
                                Continuer
                              </button>
                            </div>
                          </Animated>
                        </Form>
                        <Form hidden={currentStep === 2 ? false : true}>
                          <Animated
                            animationIn="bounceInLeft"
                            animationOut="fadeOut"
                            animationInDelay={200}
                            isVisible={true}
                          >
                            <Row>
                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="full-name" className="">
                                    Ajoutez une photo de profil
                                  </Form.Label>
                                  <input
                                    name="avatar"
                                    type="file"
                                    onChange={this.changeHandler}
                                    accept="image/jpeg,image/jpg,image/png"
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="permis" className="">
                                    Ajoutez votre permis
                                  </Form.Label>
                                  <input
                                    name="ci"
                                    type="file"
                                    onChange={this.changeHandler}
                                    accept="image/jpeg,image/jpg,image/png"
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="full-name" className="">
                                    Ajoutez votre carte grise
                                  </Form.Label>
                                  <input
                                    name="carte_grise"
                                    type="file"
                                    onChange={this.changeHandler}
                                    accept="image/jpeg,image/jpg,image/png"
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="full-name" className="">
                                    Ajoutez une photo de la plaque
                                    d'immatricualtion de votre véhicule
                                  </Form.Label>
                                  <input
                                    name="immat"
                                    type="file"
                                    onChange={this.changeHandler}
                                    accept="image/jpeg,image/jpg,image/png"
                                  />
                                </Form.Group>
                              </Col>

                              <Col
                                lg="12"
                                className="d-flex justify-content-start"
                              >
                                <Form.Check className="mb-3 form-check">
                                  <Form.Check.Input
                                    type="checkbox"
                                    id="cgu"
                                    name="cgu"
                                    onChange={this.handleChange}
                                  />
                                  <Form.Check.Label htmlFor="customCheck1">
                                    J'accepte les termes & conditions
                                  </Form.Check.Label>
                                </Form.Check>
                              </Col>
                            </Row>
                            <div className="d-flex justify-content-between">
                              <button
                                className="st-btn-md st-bg-black text-white p-h-20"
                                onClick={this.previous}
                              >
                                Précédent
                              </button>
                              {handleLoginLoading ? (
                                <button
                                  className="st-btn-md st-bg-black text-white p-h-20"
                                  disabled
                                >
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />{' '}
                                  Inscription...
                                </button>
                              ) : (
                                <button
                                  onClick={this.handleLogin}
                                  disabled={
                                    this.stepPass(
                                      this.state.item,
                                      this.state.currentStep,
                                    )
                                      ? false
                                      : true
                                  }
                                  className="st-btn-md st-bg-yellow text-black f-w-600 p-h-20"
                                >
                                  S'inscrire
                                </button>
                              )}
                            </div>
                          </Animated>
                        </Form>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div class="area">
            <img
              src={hero02}
              className="Image-fluid gradient-main animated-scaleX"
              alt=""
            />
          </div>
        </section>

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  let addedItemsTmp = state.userInfoReducer
  return {
    items: addedItemsTmp.addedUserInfos,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    addUserInfos: (item) => {
      dispatch(addUserInfos(item))
    },
    removeUserInfos: (item) => {
      dispatch(removeUserInfos(item))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)
