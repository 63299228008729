import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Navbar, Dropdown, Container, Nav, NavDropdown } from 'react-bootstrap'
import { HashLink } from 'react-router-hash-link'
// Container,Dropdown,Button
import { Badge } from 'rsuite'
import { Link, NavLink } from 'react-router-dom'
import CustomToggle from '../../../dropdowns'
// mobile-offcanvas
import MobildeOffcanvas from '../../components/mobile-offcanvas'
//Horizontal-nav
import HorizontalNav from '../HeaderStyle/horizontal-nav'

//img
import flag1 from '../../../../assets/images/Flag/flag001.png'
import flag2 from '../../../../assets/images/Flag/flag-02.png'
import flag3 from '../../../../assets/images/Flag/flag-03.png'
import flag4 from '../../../../assets/images/Flag/flag-04.png'
import flag5 from '../../../../assets/images/Flag/flag-05.png'
import flag6 from '../../../../assets/images/Flag/flag-06.png'
import avatars1 from '../../../../assets/images/avatars/01.png'
import avatars2 from '../../../../assets/images/avatars/avtar_1.png'
import avatars3 from '../../../../assets/images/avatars/avtar_2.png'
import avatars4 from '../../../../assets/images/avatars/avtar_3.png'
import avatars5 from '../../../../assets/images/avatars/avtar_4.png'
import avatars6 from '../../../../assets/images/avatars/avtar_5.png'
import shapes1 from '../../../../assets/images/shapes/01.png'
import shapes2 from '../../../../assets/images/shapes/02.png'
import shapes3 from '../../../../assets/images/shapes/03.png'
import shapes4 from '../../../../assets/images/shapes/04.png'
import shapes5 from '../../../../assets/images/shapes/05.png'
// logo
import Logo from '../../components/logo'

import {
  addUserInfos,
  removeUserInfos,
} from '../../../../store/actions/userActions'

// const this.props.currentUserInfo = JSON.parse(localStorage.getItem('userInfo'))
class HeaderStyle1 extends Component {
  state = {
    bounceIn: false,
    bounceIn02: false,
  }
  logOut = () => {
    localStorage.removeItem('userInfo')
    setTimeout(() => {
      window.location.reload()
    }, 400)
  }

  componentDidUpdate(prevProps) {
    console.log('prevProps', prevProps)
    if (prevProps.items !== this.props.items) {
      console.log('new changes is detect')
      this.setState({ bounceIn: true })
      setTimeout(() => {
        this.setState({ bounceIn: false })
      }, 1000)
    }
    if (prevProps.wishItems !== this.props.wishItems) {
      console.log('new changes is detect')
      this.setState({ bounceIn02: true })
      setTimeout(() => {
        this.setState({ bounceIn02: false })
      }, 1000)
    }
  }

  render() {
    return (
      <>
        <Navbar expand="xl" className="nav iq-navbar p-sticky st-bg-yellow">
          <Container fluid className="navbar-inner">
            <MobildeOffcanvas />
            <Navbar.Brand as="div" className="col-lg-2">
              <Link to="/" className="d-flex">
                <Logo color={true} />
              </Link>
            </Navbar.Brand>
            {/* <HorizontalNav /> */}
            <Navbar.Toggle aria-controls="navbarSupportedContent">
              <span className="navbar-toggler-icon">
                <span className="navbar-toggler-bar bar1 mt-2"></span>
                <span className="navbar-toggler-bar bar2"></span>
                <span className="navbar-toggler-bar bar3"></span>
              </span>
            </Navbar.Toggle>
            <Navbar.Collapse
              className="col-md-8 justify-content-end"
              id="navbarSupportedContent"
            >
              <Nav as="ul" className="mb-2 mb-lg-0 landing-page">
                <Nav.Item as="li">
                  <Nav.Link active aria-current="page" href="/">
                    Accueil
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <HashLink smooth className="nav-link" to="/#features">
                    Pourquoi nous choisir ?
                  </HashLink>
                </Nav.Item>
                <Nav.Item as="li">
                  <HashLink smooth className="nav-link" to="/#pricing">
                    Comment ça marche ?
                  </HashLink>
                </Nav.Item>
                <Nav.Item as="li">
                  <HashLink smooth className="nav-link" to="/#contact">
                    Contactez-nous
                  </HashLink>
                </Nav.Item>

                <Nav.Item as="li">
                  <HashLink
                    smooth
                    className="signup-nav-link nav-link"
                    to="/#deliver"
                  >
                    Devenez livreur
                  </HashLink>
                </Nav.Item>
                <Nav.Item as="li">
                  <Link className="signin-nav-link nav-link" to="/auth/sign-in">
                    Connexion
                  </Link>
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  let addedItemsTmp = state.userInfoReducer
  return {
    currentUserInfo: addedItemsTmp.addedUserInfos,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    addUserInfos: (item) => {
      dispatch(addUserInfos(item))
    },
    removeUserInfos: (item) => {
      dispatch(removeUserInfos(item))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(HeaderStyle1)
