import React from "react";
import ProtectedRoute from "./ProtectedRoute";
//router
import { Switch, Route } from "react-router";
import { BrowserRouter } from "react-router-dom";
//layoutpages
import messageDeliverCreate from "../views/messages/messageDeliverCreate";
import Default from "../layouts/dashboard/default";
import CustomerInterface from "../layouts/customer-interface";
import MaintenanceMode from "../layouts/maintenanceMode";
// import Index from '../views/index'
// import Horizontal from '../layouts/dashboard/horizontal'
// import Boxed from '../layouts/dashboard/boxed'
// import DualHorizontal from '../layouts/dashboard/dual-horizontal'
// import DualCompact from '../layouts/dashboard/dual-compact'
// import BoxedFancy from '../layouts/dashboard/boxed-fancy'
import Simple from "../layouts/dashboard/simple";

const IndexRouters = (props) => {
  return (
    <>
      <BrowserRouter basename="/#/">
        <Switch>
          <Route exact path="/" component={CustomerInterface}></Route>
          <ProtectedRoute path="/admin" component={Default} />
          <Route path="/app" component={CustomerInterface}></Route>
          <Route path="/auth" component={Simple}></Route>
          <Route path="/errors" component={Simple}></Route>
          <Route
            path="/account/created"
            component={messageDeliverCreate}
          ></Route>
          <Route path="/manage" component={Simple}></Route>
        </Switch>
        {/* <Switch>
          <Route exact path="/" component={MaintenanceMode}></Route>
          <ProtectedRoute path="/admin" component={Default} />
          <Route path="/app" component={MaintenanceMode}></Route>
          <Route path="/auth" component={Simple}></Route>
          <Route path="/errors" component={MaintenanceMode}></Route>
          <Route path="/account/created" component={MaintenanceMode}></Route>
          <Route path="/manage" component={MaintenanceMode}></Route>
        </Switch> */}
      </BrowserRouter>
    </>
  );
};

export default IndexRouters;
