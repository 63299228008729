import React from 'react'
import { Container, Image, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Card from '../../components/Card'
// img
import hero01 from '../../assets/images/pages/account-security.png'
import hero02 from '../../assets/images/pages/2205_w039_n003_238b_p1_238.png'

const Error500 = () => {
  return (
    <>
      <section className="login-content st-bg-yellow">
        <div class="context">
          <Row className="m-0 align-items-center vh-100">
            <Col md={{ span: 6, offset: 3 }}>
              <Row className="justify-content-center">
                <Col md="10">
                  <Card className="login-content-box card-transparent text-center st-bg-white shadow-none d-flex justify-content-center mb-0 auth-card">
                    <Card.Body>
                      <div className="navbar-brand d-flex justify-content-center align-items-center mb-5">
                        <Image
                          src={hero01}
                          className="img-fluid mb-4 w-50"
                          alt=""
                        />
                      </div>
                      <h2 className="mb-2">
                        Bienvenue sur notre application ''LIVO''
                      </h2>
                      <p className="mb-4">
                        Un conseiller prendra contact avec vous très
                        prochainement pour l'activation de compte dans un delais
                        de 48h 😀
                      </p>
                      <Link
                        className="st-btn-md st-bg-yellow p-2 text-white p-h-2"
                        to="/"
                      >
                        Retourner à l'accueil
                      </Link>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div class="area">
          <img
            src={hero02}
            className="Image-fluid gradient-main animated-scaleX"
            alt=""
          />
        </div>
      </section>
    </>
  )
}

export default Error500
