import { ADD_TO_CART, REMOVE_ITEM } from '../actions/action-types/cart-actions'
const initState = {
  items: [],
  addedItems: localStorage.getItem('tmpCart')
    ? JSON.parse(localStorage.getItem('tmpCart'))
    : [],
  total: 0,
}
const cartReducer = (state = initState, action) => {
  //INSIDE HOME COMPONENT
  if (action.type === ADD_TO_CART) {
    const id = action.item.id
    const isOld = state.addedItems.map((item) => item.start).includes(id)

    let addedItems = null

    if (isOld) {
      console.log('ok')
    } else {
      // addedItems = [...state.addedItems, action.item]
      addedItems = [...state.addedItems, action.item]
      setTimeout(() => {
        localStorage.setItem('tmpCart', JSON.stringify(addedItems))
      }, 1000)
      return {
        ...state,
        addedItems: addedItems,
      }
    }
  }
  if (action.type === REMOVE_ITEM) {
    let itemToRemove = state.addedItems.find(
      (item) => action.item.id === item.id,
    )
    let new_items = state.addedItems.filter(
      (item) => action.item.id !== item.id,
    )

    //calculating the total
    let newTotal = state.total - itemToRemove.price * itemToRemove.quantity
    console.log(itemToRemove)
    setTimeout(() => {
      localStorage.setItem('tmpCart', JSON.stringify(new_items))
    }, 1000)
    return {
      ...state,
      addedItems: new_items,
      total: newTotal,
    }
  } else {
    return state
  }
}
export default cartReducer
