import { IS_LOGGED, IS_DISCONNECTED } from './action-types/cart-actions'

//add cart action
export const addUserInfos = (item) => {
  return {
    type: IS_LOGGED,
    item,
  }
}

//remove item action
export const removeUserInfos = (item) => {
  return {
    type: IS_DISCONNECTED,
    item,
  }
}
