import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../../../assets/images/logo-sanza.png'
import Logo from '../../components/logo'

const Footer = () => {
  return (
    <footer className="front footer text-left">
      {/* <div className="container-fluid">
        <div className="row">
          <div className="col-md-2">
            <h3>ENTREPRISE</h3>
            <ul className="left-panel list-inline mb-0 p-0">
              <li className="list-inline-item">
                <Link to="/dashboard/extra/privacy-policy">Blog</Link>
              </li>
              <li className="list-inline-item">
                <Link to="/dashboard/extra/terms-of-service">Support</Link>
              </li>
              <li className="list-inline-item">
                <Link to="/dashboard/extra/terms-of-service">Carrières</Link>
              </li>
              <li className="list-inline-item">
                <Link to="/dashboard/extra/terms-of-service">
                  Devenir partenaire
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-2">
            <h3>CHANSONS</h3>
            <ul className="left-panel list-inline mb-0 p-0">
              <li className="list-inline-item">
                <Link to="/dashboard/extra/privacy-policy">Playlists</Link>
              </li>
              <li className="list-inline-item">
                <Link to="/dashboard/extra/terms-of-service">Artistes</Link>
              </li>
              <li className="list-inline-item">
                <Link to="/dashboard/extra/terms-of-service">Boutique</Link>
              </li>
              <li className="list-inline-item">
                <Link to="/dashboard/extra/terms-of-service">Chansons</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-2">
            <h3>POUR LES ARTISTES</h3>
            <ul className="left-panel list-inline mb-0 p-0">
              <li className="list-inline-item">
                <Link to="/dashboard/extra/privacy-policy">
                  Communité d'artistes
                </Link>
              </li>
              <li className="list-inline-item">
                <Link to="/dashboard/extra/terms-of-service">
                  Portail artistes
                </Link>
              </li>
              <li className="list-inline-item">
                <Link to="/dashboard/extra/terms-of-service">
                  Application d'artistes
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-4  offset-2">
            <h3>REJOINDRE LA LISTE DE NOTRE MAILING</h3>
            <input type="email" className="form-control" />
          </div>
        </div>
      </div> */}
      <div className="region-copyright">
        <div className="left-panel">
          {/* <img src={logo} alt="logo" /> */}
          <Link to="/" className="d-flex logo-title-footer">
            <h4>Livo</h4>
          </Link>
          <p>Copyright 2023 - Tous droits réservés @ 2023</p>
        </div>
        <div className="help">
          <Link className="sz-btn with-border-dark c-p">
            <i class="fas fa-question-circle"></i> Aide
          </Link>
        </div>
      </div>
    </footer>
  )
}

export default Footer
