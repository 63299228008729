import axios from "axios";
import config from "./config";

const headersConfig = (token) => {
  const head = { headers: { Authorization: `Bearer ${token}` } };
  return head;
};

export default {
  packageList: (data, token) => {
    // console.log(data);
    return axios.post(
      `${config.URL_END_POINT}/package/list`,
      data,
      headersConfig(token)
    );
  },
  packageCreate: (data, token) => {
    // console.log(data);
    return axios.post(
      `${config.URL_END_POINT}/package/add`,
      data,
      headersConfig(token)
    );
  },
  packageById: (id, token) => {
    return axios.get(
      `${config.URL_END_POINT}/package/${id}`,
      headersConfig(token)
    );
  },
  packageByCode: (code, token) => {
    return axios.get(
      `${config.URL_END_POINT}/package/code/${code}`,
      headersConfig(token)
    );
  },
  packageByDeliveryId: (id, token) => {
    return axios.get(
      `${config.URL_END_POINT}/package/by-delivery-id/${id}`,
      headersConfig(token)
    );
  },
  packageByStatutAndDate: (status, isCurrent, date, token) => {
    return axios.get(
      `${config.URL_END_POINT}/package/status/${status}/${isCurrent}/${date}`,
      headersConfig(token)
    );
  },
  packageByStatutAndDateRange: (
    status,
    isCurrent,
    startDate,
    endDate,
    token
  ) => {
    return axios.get(
      `${config.URL_END_POINT}/package/status/${status}/${isCurrent}/${startDate}/${endDate}`,
      headersConfig(token)
    );
  },
  packageUpdate: (id, data, token) => {
    return axios.put(
      `${config.URL_END_POINT}/package/update/${id}`,
      data,
      headersConfig(token)
    );
  },
  packageStatusChange: (id, data, token) => {
    return axios.put(
      `${config.URL_END_POINT}/package/status-change/${id}`,
      data,
      headersConfig(token)
    );
  },
};
