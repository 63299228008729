import React, { Component } from 'react'
import Loader01 from '../../loaders/loader_01'
import emptyImg from '../../assets/images/empty-folder.png'

class Empty extends Component {
  constructor(props) {
    super(props)
    this.state = {
      load: true,
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        load: false,
      })
    }, 2000)
  }

  render() {
    return (
      <div className="empty-list text-center">
        {this.state.load && <Loader01 />}
        {!this.state.load && (
          <div>
            <img src={emptyImg} alt="" />
            <p>Aucune donnée trouvée.</p>
          </div>
        )}
      </div>
    )
  }
}

export default Empty
