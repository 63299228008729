import axios from 'axios'

import config from './config'

export default {
  roleCreate: (data) => {
    return axios.post(`${config.URL_END_POINT}/role/add`, data)
  },
  roleList: () => {
    return axios.get(`${config.URL_END_POINT}/role/list`)
  },
  roleById: (id) => {
    return axios.get(`${config.URL_END_POINT}/role/${id}`)
  },
  roleUpdate: (id, data) => {
    return axios.put(`${config.URL_END_POINT}/role/update/${id}`, data)
  },
}
