import React, { Component } from 'react'
import loader01 from '../assets/images/loader/03.gif'
import loader001 from '../assets/images/loader/003.gif'
import loader0001 from '../assets/images/loader/0003.gif'

class Loader01 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loader: loader01,
    }
  }

  changeGifLoader() {
    setInterval(() => {
      setTimeout(() => {
        this.setState({ loader: loader01 })
      }, 1000)
      setTimeout(() => {
        this.setState({ loader: loader001 })
      }, 2000)
      setTimeout(() => {
        this.setState({ loader: loader0001 })
      }, 3000)
    }, 4000)
  }

  componentDidMount() {
    this.changeGifLoader()
  }

  render() {
    const { loader } = this.state
    return <img src={loader} className="submit-loader" alt="img" />
  }
}

export default Loader01
