//Types should be in const to avoid typos and duplication since it's a string and could be easily miss spelled
export const ADD_TO_CART = 'ADD_TO_CART'
export const REMOVE_ITEM = 'REMOVE_ITEM'
export const IS_LOGGED = 'IS_LOGGED'
export const IS_DISCONNECTED = 'IS_DISCONNECTED'
export const ADD_TO_WISH = 'ADD_TO_WISH'
export const REMOVE_WISH_ITEM = 'REMOVE_WISH_ITEM'
export const SUB_QUANTITY = 'SUB_QUANTITY'
export const ADD_QUANTITY = 'ADD_QUANTITY'
export const ADD_SHIPPING = 'ADD_SHIPPING'
