import React, { Component } from 'react'
// import axios from 'axios'
// import Moment from 'react-moment'
// import { Link, NavLink } from 'react-router-dom'
// import ReactDOMServer from 'react-dom/server'

// reactstrap components
import {
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
  // UncontrolledDropdown,
  Modal,
  // Collapse,
  // NavbarBrand,
  // Navbar,
  // NavItem,
  // Nav,
  // Container,
  // Button,
} from 'react-bootstrap'

import auth from '../../../restApiService/auth'
import account from '../../../restApiService/account'

class Users extends Component {
  constructor(props) {
    super(props)
    this.state = {
      item: [],
      userList: [],
      array: [],
      modal01: false,
    }
    this.handleChange = this.handleChange.bind(this)
  }

  createUserEntity = (event) => {
    event.preventDefault()
    let item = { ...this.state.item }
    item['roles'] = this.state.array
    auth
      .signUp(item)
      .then((res) => {
        this.setState({ item: [] })
        this.componentDidMount()
        this.toggleModal01()
      })
      .catch((error) => {})
  }

  fetchEntityList() {
    account
      .getUserList()
      .then((res) => {
        this.setState({ userList: res.data.user })
      })
      .catch((error) => {})
  }

  handleChange(event) {
    const target = event.target
    const value = target.value
    const name = target.name
    let item = { ...this.state.item }
    item[name] = value
    if (name === 'roles') {
      this.setState({ array: [...this.state.array, value] })
    }
    this.setState({ item })
  }

  toggleModal01 = () => {
    this.setState({
      modal01: !this.state.modal01,
    })
  }

  componentDidMount() {
    this.fetchEntityList()
  }

  render() {
    const { userList } = this.state

    return (
      <section className="content-page">
        <div className="row">
          <div className="col-md-12">
            <div className="page-title mb-5">
              <h2>Gestion des utilisations</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="mt-2 mb-4 text-right">
              <button
                className="btn btn-borded btn-full-blue-violent"
                onClick={this.toggleModal01}
              >
                Ajouter un utilisateur
              </button>
            </div>
          </div>
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <h5>Liste des utilisateurs</h5>
              </div>
              <div className="card-block table-border-style Recent-Users">
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Nom</th>
                        <th>Prénom</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {userList.map((user, index) => (
                        <tr key={index}>
                          <td>{index + 1} </td>
                          <td>{user.last_name} </td>
                          <td>{user.first_name} </td>
                          <td>{user.email} </td>
                          <td>{user.roles.map((role) => role.name)} </td>
                          <td> </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal open={this.state.modal01} onClose={this.toggleModal01}>
          <div className="modal-header card-header">
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={this.toggleModal01}
            >
              <i className="nc-icon nc-simple-remove"></i>
            </button>
            <h5>Créer un utilisation</h5>
          </div>
          <div className="modal-body">
            <div className="row">
              {/* {successOperattion && (
                <div className="col-md-12">
                  <span className="success-message">
                    Votre produit a été ajouté avec succès.
                  </span>
                </div>
              )} */}
              <div className="col-md-12">
                <div className="form--box">
                  <div className="">
                    <div className="mt-2">
                      {/* {Error && (
                        <p className="text-danger text-center">{ErrorMessage}.</p>
                      )} */}
                      <form onSubmit={this.createUserEntity}>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="input-group mb-3">
                              <label>Civilité</label>

                              <select
                                className="border-input form-control"
                                name="sexe"
                                onChange={this.handleChange}
                              >
                                <option></option>
                                <option value="M">M</option>
                                <option value="Mme">Mme</option>
                                <option value="Mlle">Mlle</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="input-group mb-3">
                              <label>Nom</label>
                              <input
                                placeholder=""
                                type="text"
                                name="last_name"
                                className="border-input form-control"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-8">
                            <div className="input-group mb-3">
                              <label>Prénom</label>
                              <input
                                placeholder=""
                                type="text"
                                name="first_name"
                                className="border-input form-control"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="input-group mb-3">
                              <label>Email</label>
                              <input
                                placeholder=""
                                type="text"
                                name="email"
                                className="border-input form-control"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="input-group mb-3">
                              <label>Mot de passe</label>
                              <input
                                placeholder=""
                                type="password"
                                name="password"
                                className="border-input form-control"
                                onChange={this.handleChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            {' '}
                            <div className="input-group mb-3">
                              <label>Role</label>

                              <select
                                className="border-input form-control"
                                name="roles"
                                onChange={this.handleChange}
                              >
                                <option></option>
                                <option value="admin">Administrateur</option>
                                <option value="gestionnaire">
                                  Gestionnaire
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="form-group text-right">
                          <button className="btn btn-borded btn-full-blue-violent">
                            Enregistrer
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </section>
    )
  }
}

export default Users
