import axios from "axios";
import config from "./config";

const headersConfig = (token) => {
  const head = { headers: { Authorization: `Bearer ${token}` } };
  return head;
};

export default {
  deliveryList: (token) => {
    // console.log(data);
    return axios.get(
      `${config.URL_END_POINT}/delivery/list`,
      headersConfig(token)
    );
  },
  pendingDeliveryList: (token) => {
    // console.log(data);
    return axios.get(
      `${config.URL_END_POINT}/delivery/pending/list`,
      headersConfig(token)
    );
  },
  deliveryByStatutAndDate: (status, isCurrent, date, token) => {
    return axios.get(
      `${config.URL_END_POINT}/delivery/status/${status}/${isCurrent}/${date}`,
      headersConfig(token)
    );
  },
  deliveryByStatutAndDateRange: (
    status,
    isCurrent,
    startDate,
    endDate,
    token
  ) => {
    return axios.get(
      `${config.URL_END_POINT}/delivery/status/${status}/${isCurrent}/${startDate}/${endDate}`,
      headersConfig(token)
    );
  },
  deliveryCreate: (data, token) => {
    // console.log(data);
    return axios.post(
      `${config.URL_END_POINT}/delivery/add`,
      data,
      headersConfig(token)
    );
  },
  deliveryAcceptByDeliver: (data, token) => {
    return axios.post(
      `${config.URL_END_POINT}/delivery_deliver/add`,
      data,
      headersConfig(token)
    );
  },
  deliveryById: (id, token) => {
    return axios.get(
      `${config.URL_END_POINT}/delivery/${id}`,
      headersConfig(token)
    );
  },
  deliveryInProgressByDeliverId: (deliverId, token) => {
    return axios.get(
      `${config.URL_END_POINT}/delivery/in-progress/${deliverId}`,
      headersConfig(token)
    );
  },
  deliveryByDeliverId: (deliverId, token) => {
    return axios.get(
      `${config.URL_END_POINT}/delivery/for-one-deliver/${deliverId}`,
      headersConfig(token)
    );
  },
  deliveryUpdate: (id, data, token) => {
    return axios.put(
      `${config.URL_END_POINT}/delivery/update/${id}`,
      data,
      headersConfig(token)
    );
  },
};
