import { createStore, combineReducers } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import Mode from './setting/setting'
import CartReducer from './cartReducer/cartReducer'
import WishReducer from './wishReducer/wishReducer'
import UserInfoReducer from './userInfoReducer/userInfoReducer'
const persistConfig = {
  key: 'root',
  storage,
}
const persistedReducer = persistReducer(persistConfig, CartReducer)
const persistedReducer02 = persistReducer(persistConfig, WishReducer)
const persistedReducer03 = persistReducer(persistConfig, UserInfoReducer)

// export default () => {
//   let store = createStore(persistedReducer)
//   let persistor = persistStore(store)
//   return { store, persistor }
// }
export default createStore(
  combineReducers({
    mode: Mode,
    cartReducer: persistedReducer,
    wishReducer: persistedReducer02,
    userInfoReducer: persistedReducer03,
  }),
)
